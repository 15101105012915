<template>
	<div>
		<div class="ver-panel">
			<div class="ver-head">
				<div class="l-title">完善资料</div>
				<div class="l-desc">
					为了保障您的账号安全，请补充手机信息
				</div>
			</div>
			<el-form :model="ruleForm" >
				<el-form-item prop="phone">
					<div class="input-select-group">
						<el-select v-model="ruleForm.select" slot="prepend" placeholder="请选择">
							<el-option label="中国+86" value="1"></el-option>
							<el-option label="中国+86" value="2"></el-option>
							<el-option label="中国+86" value="3"></el-option>
						</el-select>
						<el-input v-model="ruleForm.phone"  placeholder="请输入您的手机号">
						</el-input>
					</div>
				</el-form-item>
				<el-form-item prop="code">
					<el-input v-model="ruleForm.code"  placeholder="请输入6位短信验证码">
						<el-button type="primary" slot="suffix" class="btn btn-getcode">获取验证码</el-button>
					</el-input>
				</el-form-item>
				<div class="ver-foot">
					<el-button class="btn-submit" type="primary" @click="submitForm">确认提交</el-button>
				</div>
				<div class="login-end">
					<span>跳过下次再完善</span>
				</div>
			</el-form>
		</div>
		<div class="ask-panel">
			<div class="ask-panel-hd">常见问题</div>
			<ul class="ask-list">
				<li>
					<div class="ask-title">1、为什么我需要完善信息?</div>
					<div class="ask-desc">当前系统未录入您的手机信息，可能会导致您在敏感操作时无法进行身份验证，或无法接收到重要消息通知。因此需要您完善手机信息并且完成手机消息渠道验证。</div>
				</li>
				<li>
					<div class="ask-title">2、后续我的信息哪里进行修改?</div>
					<div class="ask-desc">当前系统未录入您的手机信息，可能会导致您在敏感操作时无法进行身份验证，或无法接收到重要消息通知。因此需要您完善手机信息并且完成手机消息渠道验证。</div>
				</li>
				<li>
					<div class="ask-title">3、是否可以下次再进行完善信息?</div>
					<div class="ask-desc">当前系统未录入您的手机信息，可能会导致您在敏感操作时无法进行身份验证，或无法接收到重要消息通知。因此需要您完善手机信息并且完成手机消息渠道验证。</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		components: {
		},
		data() {
			return {
				ruleForm: {
					select:"1",
					phone: '',
					code: '',
				},
			};
		},
		mounted() {},
		methods: {
			submitForm() {
				
			},
		},
	};
</script>

<style scoped="scoped">
	.ask-list li{
		padding: 10px 0;
		font-size: 14px;
		border-bottom: 1px solid #D3D7DD;
		.ask-title{
			color: #0A7AFF;
			margin-bottom: 5px;
		}
		.ask-desc{
			color: #999999;
			font-size: 13px;
		}
	}
	.ask-panel{
		padding: 20px ;
		background-color: #EBEFF4;
		.ask-panel-hd{
			font-size: 16px;
			line-height: 1;
			padding-left: 10px;
			border-left: 3px solid #0A7AFF;
			font-weight: bold;
			margin-bottom: 10px;
		}
	}
	.ver-head {
		height: 60px;
		color: #333;
		margin-bottom: 20px;
		.l-title {
			font-size: 20px;
		}
		.l-sub-title {
			font-size: 13px;
		}

		.l-desc {
			color: #999;
			margin-top: 10px;
			font-size: 12px;
			
		}
	}

	.btn-getcode {
		display: inline-flex;
		border: 0;
		padding: 8px 12px;
		border-radius: 6px;
		font-size: 12px;
		cursor: pointer;
		background-color: #007BF7;
		border-color: #007BF7;
	}

	.ver-panel {
		padding: 100px 20px 20px 20px;
		box-sizing: border-box;
	}

	.ver-panel ::v-deep .el-input__inner {
		background: #ECEEF2;
		border-radius: 10px;
		border-color: #ECEEF2 !important;
	}

	.ver-panel ::v-deep .el-input__inner::placeholder {
		color: #666;
	}

	.ver-panel .btn-submit {
		width: 100%;
		margin: 0;
		height: 46px;
		border-radius: 16px;
	}

	.ver-panel .btn-submit:not(.is-disabled) {
		background: linear-gradient(90deg, #007BF7 0%, #0AABFB 100%);
	}

	.flex_bd {
		flex: 1;
		min-width: 0;
	}

	.input-select-group {
		position: relative;
	}

	.input-select-group .el-select {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 119;
		width: 100px;
	}

	.input-select-group .el-input ::v-deep .el-input__inner {
		padding-left: 100px !important;
	}

	.ver-phone-cell {
		font-size: 14px;
	}

	.agr-cell ::v-deep .el-checkbox__label {
		color: #666 !important;
		font-size: 12px;
	}

	.agr-label {
		color: #007bf7;
		font-size: 12px;
	}

	.agr-cell {
		display: flex;
		align-items: center;
		white-space: nowrap;
		font-size: 12px;
		margin-bottom: 30px;
	}

	.other-hd {
		position: relative;
		text-align: center;
		margin-bottom: 20px;
		margin-top: 50px;
	}

	.other-hd span {
		position: relative;
		z-index: 2;
		background-color: #FFFFFF;
		padding: 0 20px;
		font-size: 14px;
		display: inline-block;
	}

	.other-hd::after {
		height: 1px;
		background-color: #999999;
		content: '';
		width: 100%;
		position: absolute;
		left: 0;
		top: 50%;
	}

	.login-other {
		display: flex;
		padding: 0 30px;
		.col {
			flex: 1;
			text-align: center;
		}

		.login-item img {
			width: 40px;
		}

		.login-item .l-label {
			color: #666;
			font-size: 14px;
			margin-top: 10px;
		}
	}

	.login-end {
		text-align: center;
		font-size: 14px;
		margin-top: 10px;
		span {
			color: #007BF7;
		}
	}
</style>